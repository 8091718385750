import { template as template_89010497f2e145daa7764ea1da9a1725 } from "@ember/template-compiler";
const FKText = template_89010497f2e145daa7764ea1da9a1725(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
