import { template as template_74dac56f78f9421097b81f289b0bcb55 } from "@ember/template-compiler";
const FKControlMenuContainer = template_74dac56f78f9421097b81f289b0bcb55(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
