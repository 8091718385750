import { template as template_1784cc6fefc34476b442e93b2b173fca } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_1784cc6fefc34476b442e93b2b173fca(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
