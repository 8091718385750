import { template as template_16cdb7211fe8471597148eb295ea399b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_16cdb7211fe8471597148eb295ea399b(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
