import { template as template_9313192172684139b5ef097dc5eed0b4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9313192172684139b5ef097dc5eed0b4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
