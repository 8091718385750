import { template as template_e35d2883bb9d477b8780432325e5dc3f } from "@ember/template-compiler";
const WelcomeHeader = template_e35d2883bb9d477b8780432325e5dc3f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
